button, hr, input {
  overflow: visible;
}

audio, canvas, progress, video {
  display: inline-block;
}

progress, sub, sup {
  vertical-align: baseline;
}

[type="checkbox"], [type="radio"], legend {
  box-sizing: border-box;
  padding: 0;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

article, aside, details, figcaption, figure, footer, header, main, menu, nav, section {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
}

code, kbd, pre, samp {
  font-family: monospace;
  font-size: 1em;
}

a {
  -webkit-text-decoration-skip: objects;
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

dfn {
  font-style: italic;
}

mark {
  color: #000;
  background-color: #ff0;
}

small {
  font-size: 80%;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
}

button, select {
  text-transform: none;
}

[type="reset"], [type="submit"], button, html [type="button"] {
  -webkit-appearance: button;
}

[type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner, button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring, button:-moz-focusring {
  outline: ButtonText dotted 1px;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  color: inherit;
  max-width: 100%;
  white-space: normal;
  display: table;
}

textarea {
  overflow: auto;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

[hidden], template {
  display: none;
}

:root {
  --light-blue: #d8ecf2;
  --dark-blue: #054771;
  --lightest-grey: #f8f8f8;
  --lighter-grey: #e7e7e7;
  --light-grey: #ccc;
  --dark-grey: #4e6169;
  --green: #b6e7d4;
  --main-bg: #b6e7d4;
}

:root {
  --font-base: "Space Grotesk", -apple-system, BlinkMacSystemFont, "Segoe UI", Arial, sans-serif;
  --fw-bold: 700;
  --fw-base: 500;
  --fz-h1: 6.2rem;
  --lh-h1: 1.4;
  --fz-h2: 4.2rem;
  --lh-h2: 1.4;
  --fz-h3: 3.4rem;
  --fz-h4: 2.4rem;
  --fz-base: 2rem;
  --lh-base: 1.65;
  --fz-xlarge: 2.8rem;
  --fz-large: 2.2rem;
  --fz-small: 1.6rem;
  --fz-xsmall: 1.3rem;
  --ls-base: -.015rem;
  --ls-large: .15rem;
}

@media (max-width: 47.999em) {
  :root {
    --fz-h1: 4.2rem;
    --fz-h2: 2.6rem;
    --fz-h3: 2.2rem;
    --fz-h4: 2rem;
    --fz-xlarge: 2.2rem;
    --fz-large: 1.8rem;
    --fz-small: 1.6rem;
  }
}

:root {
  --m-small: 1.5rem;
  --m-base: 2.5rem;
  --m-large: 5rem;
  --m-space: 8rem;
  --space-base: 90rem;
  --border-radius-small: .6rem;
  --border-radius-big: 2rem;
  --z-base: 10;
  --z-mobilenav: 15;
  --z-content: 18;
  --z-menu: 99;
  --z-cookies: 100;
}

@media (max-width: 47.999em) {
  :root {
    --m-space: 50px;
    --m-large: 40px;
    --m-base: 20px;
    --m-small: 10px;
  }
}

@font-face {
  font-family: Space Grotesk;
  src: local(Space Grotesk Regular), url("SpaceGrotesk-Regular.87c02be1.woff");
  font-weight: 400;
  text-rendering: optimizeLegibility;
  font-display: swap;
}

@font-face {
  font-family: Space Grotesk;
  src: local(Space Grotesk Medium), url("SpaceGrotesk-Medium.210d780c.woff");
  font-weight: 500;
  text-rendering: optimizeLegibility;
  font-display: swap;
}

@font-face {
  font-family: Space Grotesk;
  src: local(Space Grotesk SemiBold), local(Space Grotesk Bold), url("SpaceGrotesk-Bold.419d0539.woff");
  font-weight: 700;
  text-rendering: optimizeLegibility;
  font-display: swap;
}

html {
  min-height: 100vh;
  box-sizing: border-box;
  font-size: 56.25%;
  overflow-x: hidden;
  overflow-y: visible;
}

@media (min-width: 48em) {
  html {
    font-size: 40%;
  }
}

@media (min-width: 64em) {
  html {
    font-size: 50%;
  }
}

@media (min-width: 80em) {
  html {
    font-size: 62.5%;
  }
}

@media (max-width: 47.999em) {
  html.is-locked {
    max-height: 100%;
    width: 100%;
    position: fixed;
    overflow: hidden;
  }
}

body {
  min-height: 100vh;
  font-size: 2rem;
  font-size: var(--fz-base);
  letter-spacing: -.015rem;
  letter-spacing: var(--ls-base);
  line-height: 1.65;
  line-height: var(--lh-base);
  font-family: Space Grotesk, -apple-system, BlinkMacSystemFont, Segoe UI, Arial, sans-serif;
  font-family: var(--font-base);
  color: #054771;
  color: var(--dark-blue);
  font-weight: 500;
  font-weight: var(--fw-base);
  flex-direction: column;
  display: flex;
}

main {
  width: 100%;
  outline: 0;
  flex: 1 0 auto;
  position: relative;
}

@media (min-width: 48em) {
  main {
    z-index: 10;
    z-index: var(--z-base);
  }
}

*, :before, :after {
  box-sizing: inherit;
}

.vh {
  height: 1px;
  width: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute;
  overflow: hidden;
}

.skip-link {
  z-index: 10001;
  background: hsl(var(--c-base));
  color: #fff;
  padding: .5rem 1rem;
  position: absolute;
  top: 0;
  left: -9999px;
}

.skip-link:focus {
  left: 0;
}

[data-content-reveal="basic"] > *, [data-content-reveal="overview"] > *, [data-content-reveal="trigger"], [data-content-reveal="articlePage"] {
  opacity: 0;
}

.s-hidden {
  visibility: hidden;
  opacity: 0;
  transform: translateY(100%);
}

hr {
  border: 0;
  border-top: 1px solid #ccc;
  border-top: 1px solid var(--light-grey);
  margin: 10rem 0;
  margin: calc(var(--m-large) * 2) 0;
}

main:focus {
  outline: 0;
}

p:empty {
  margin: 0;
  display: none;
}

.l-container {
  width: 100%;
  position: relative;
}

.l-container + .l-container:not([class*="--no-margin"]) {
  margin-top: 8rem;
  margin-top: var(--m-space);
  margin-bottom: 8rem;
  margin-bottom: var(--m-space);
}

.l-container--no-margin-bottom {
  margin-bottom: 0 !important;
}

.l-container--bg-color {
  width: calc(100% - 10rem);
  width: calc(100% - var(--m-large) * 2);
  max-width: 140rem;
  margin: 0 auto;
  position: relative;
}

.l-content {
  width: calc(100% - 10rem);
  width: calc(100% - var(--m-large) * 2);
  max-width: 140rem;
  margin: 0 auto;
  padding-left: env(safe-area-inset-left) !important;
  padding-right: env(safe-area-inset-right) !important;
}

@media (min-width: 64em) {
  .l-content--small {
    max-width: 90rem;
    max-width: var(--space-base);
  }
}

.l-image__parent {
  position: relative;
  overflow: hidden;
}

.l-image__parent--landscape:before {
  content: "";
  padding-top: 62.25%;
  display: block;
}

.l-image__parent--landscape > img {
  position: absolute;
  top: 0;
  left: 0;
}

.l-image__parent--square:before {
  content: "";
  padding-top: 100%;
  display: block;
}

.l-image__parent--square > img {
  position: absolute;
  top: 0;
  left: 0;
}

.l-image__parent--portrait:before {
  content: "";
  padding-top: 110%;
  display: block;
}

.l-image__parent--portrait > img {
  position: absolute;
  top: 0;
  left: 0;
}

.l-image__item {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity .75s cubic-bezier(.02, .61, .15, .96);
}

.l-image__item--contain {
  object-fit: contain;
}

.l-image__item--border-radius {
  border-radius: 2rem;
  border-radius: var(--border-radius-big);
}

picture {
  width: 100%;
  display: block;
}

img:not([src]):not([srcset]) {
  visibility: hidden;
}

.l-item * {
  margin: 0;
}

.l-item > * + * {
  margin-top: 2.5rem;
  margin-top: var(--m-base);
}

.l-item--xs > * + * {
  margin-top: .5rem;
}

.l-item--sm > * + * {
  margin-top: 1.5rem;
  margin-top: var(--m-small);
}

.l-item--lg > * + * {
  margin-top: 5rem;
  margin-top: var(--m-large);
}

.l-item--xl > * + * {
  margin-top: 7.5rem;
  margin-top: calc(var(--m-large)  + var(--m-base));
}

.l-item--form > * + * {
  margin-top: 4rem;
}

@media (max-width: 47.999em) {
  .l-item--form > * + * {
    margin-top: 2rem;
  }
}

@media (max-width: 47.999em) {
  .l-item--m-sm > * + * {
    margin-top: 1.5rem;
    margin-top: var(--m-small);
  }
}

@media (max-width: 47.999em) {
  .l-item--m-md > * + * {
    margin-top: 2.5rem;
    margin-top: var(--m-base);
  }
}

.l-list {
  padding: 0;
  list-style: none;
}

.l-list--inline {
  flex-wrap: wrap;
  display: flex;
}

@media (max-width: 47.999em) {
  .l-list--inline {
    width: 100%;
    display: block;
  }

  @media (max-width: 47.999em) {
    .l-list--inline > * + * {
      width: 100%;
      display: block;
    }
  }
}

@media (max-width: 47.999em) {
  .l-list--inline > * + * {
    margin-top: 2.5rem;
    margin-top: var(--m-base);
  }
}

@media (min-width: 48em) {
  .l-list--inline > * + * {
    margin-left: 2.5rem;
    margin-left: var(--m-base);
  }
}

.g-header-1__grid {
  grid-column-gap: 10rem;
  grid-row-gap: 6rem;
  grid-template-columns: minmax(200px, 1fr) 3fr;
  grid-template-areas: "intro title"
                       "cvlink cvlink";
  align-items: flex-end;
  padding-top: 18rem;
  display: grid;
}

@media (max-width: 47.999em) {
  .g-header-1__grid {
    grid-template-columns: 1fr;
    grid-template-areas: "title"
                         "intro"
                         "cvlink";
    padding-top: 12rem;
  }
}

.g-header-1__intro {
  opacity: 0;
  grid-area: intro;
  transform: translateY(1vw);
}

.g-header-1__title {
  grid-area: title;
}

.g-header-1__title h1 {
  opacity: 0;
  transform: translateY(1vw);
}

.g-header-1__cv-link {
  opacity: 0;
  grid-area: cvlink;
  transform: translateY(1vw);
}

.g-header-1__avatar {
  width: 6.5rem;
  height: 6.5rem;
}

.g-work-1__grid {
  grid-template-columns: 1fr;
  display: grid;
}

@media (min-width: 48em) {
  .g-work-1__grid {
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    align-items: center;
    gap: 6rem 12rem;
  }
}

.g-skills-1__grid {
  padding: 8rem 0;
  padding: var(--m-space) 0;
  grid-row-gap: 2rem;
  grid-template-columns: 1fr;
  font-weight: 400;
  display: grid;
}

@media (min-width: 48em) {
  .g-skills-1__grid {
    grid-column-gap: 16rem;
    grid-template-columns: 1fr 1fr;
  }
}

.g-skills-1__text {
  font-size: 2.2rem;
  font-size: var(--fz-large);
}

.g-skills-1__skills-list {
  padding: 0;
  list-style: none;
}

.g-skills-1__skills-list li {
  font-size: 2.8rem;
  font-size: var(--fz-xlarge);
}

.g-skills-1__skills-list li:not(:first-child) {
  margin-top: 2rem;
}

.g-experience-1__grid {
  grid-row-gap: 6rem;
  grid-template-columns: 1fr;
  font-weight: 400;
  display: grid;
}

.g-experience-1__item {
  grid-column-gap: 10rem;
  grid-template-columns: 1fr 3fr;
  align-items: flex-start;
  display: grid;
}

@media (max-width: 47.999em) {
  .g-experience-1__item {
    grid-column-gap: 4rem;
  }
}

.g-experience-1__year {
  font-size: 1.6rem;
  font-size: var(--fz-small);
  color: #4e6169;
  color: var(--dark-grey);
}

.g-experience-1__year p {
  margin: .5rem 0 0;
}

.g-experience-1__title h2 {
  color: #000;
  font-size: 3.4rem;
  font-size: var(--fz-h3);
  font-weight: 400;
}

.g-experience-1__title h2 span {
  color: #054771;
  color: var(--dark-blue);
  font-weight: 700;
  font-weight: var(--fw-bold);
}

.g-experience-1__skills-list {
  padding: 0;
  list-style: none;
}

.g-experience-1__skills-list li {
  font-size: 2.8rem;
  font-size: var(--fz-xlarge);
}

.g-experience-1__skills-list li:not(:first-child) {
  margin-top: 1rem;
}

.g-work-project-1__content {
  padding-top: 6rem;
}

.g-work-project-1__intro-picture {
  max-height: 64rem;
}

.g-work-project-1__intro-picture img {
  filter: grayscale();
}

.g-work-project-1__intro-grid {
  grid-row-gap: 6rem;
  color: #000;
  grid-template-areas: "title"
                       "intro"
                       "facts";
  display: grid;
}

.g-work-project-1__intro-title {
  grid-area: title;
}

.g-work-project-1__intro-text {
  font-size: 2.8rem;
  font-size: var(--fz-xlarge);
  color: #000;
  grid-area: intro;
}

.g-work-project-1__intro-facts {
  flex-wrap: wrap;
  grid-area: facts;
  justify-content: space-between;
  align-items: center;
  row-gap: 2rem;
  display: flex;
}

.g-work-project-1__intro-fact-item h3 {
  font-size: var(--fz-xs);
  color: #4e6169;
  color: var(--dark-grey);
  font-weight: 400;
}

.g-work-project-1__intro-fact-item a, .g-work-project-1__intro-fact-item p {
  color: #000;
  font-size: 2.2rem !important;
  font-weight: bold !important;
  font-size: var(--fz-large) !important;
}

.g-work-project-1__intro-fact-item:not(:last-child) {
  margin-right: 2rem;
}

.g-work-project-1__back-button {
  padding: 5rem 0;
  padding: var(--m-large) 0;
  text-align: center;
  border-top: 1px solid #ccc;
  border-top: 1px solid var(--light-grey);
}

.c-footer__wrapper {
  background-color: #f8f8f8;
  background-color: var(--lightest-grey);
  color: #000;
}

.c-footer__grid {
  padding: 8rem 0;
  padding: var(--m-space) 0;
  grid-row-gap: 6rem;
  grid-template-columns: 1fr;
  display: grid;
}

strong, .bold {
  font-weight: 700;
  font-weight: var(--fw-bold);
}

em, i, .italic {
  font-style: italic;
}

a:not([class]), a[href].base-link {
  font-weight: 500;
  font-weight: var(--fw-base);
  text-decoration: underline;
  transition: color .25s;
}

:is(a:not([class]), a[href].base-link):hover {
  color: hsl(var(--c-body-h), var(--c-body-s), calc(var(--c-body-l)  + 33%));
  text-decoration: none;
}

:is(a:not([class]), a[href].base-link):focus {
  color: hsl(var(--c-body-h), var(--c-body-s), calc(var(--c-body-l)  + 25%));
  text-decoration: none;
}

a:not([href]) {
  pointer-events: none;
  cursor: default;
}

ul:not([class]), ol:not([class]) {
  padding-left: 1.6rem;
}

a, button {
  cursor: pointer;
  color: hsl(var(--c-body));
  text-decoration: none;
}

a[href^="tel:"] {
  cursor: none;
  text-decoration: none;
}

:is(h1, h2, h3, h4, h5, h6) {
  font-family: Space Grotesk, -apple-system, BlinkMacSystemFont, Segoe UI, Arial, sans-serif;
  font-family: var(--font-base);
  font-weight: 700;
  font-weight: var(--fw-bold);
  margin: 0;
  position: relative;
}

h1, .h1 {
  font-size: 6.2rem;
  font-size: var(--fz-h1);
  line-height: 1.4;
  line-height: var(--lh-h1);
  font-weight: 700;
  font-weight: var(--fw-bold);
}

h2, .h2 {
  font-size: 4.2rem;
  font-size: var(--fz-h2);
  line-height: 1.4;
  line-height: var(--lh-h2);
  font-weight: 700;
  font-weight: var(--fw-bold);
}

h3, .h3 {
  font-size: 3.4rem;
  font-size: var(--fz-h3);
  line-height: 1.4;
  line-height: var(--lh-h2);
  font-weight: 700;
  font-weight: var(--fw-bold);
}

h4, .h4 {
  font-size: 2.4rem;
  font-size: var(--fz-h4);
  line-height: 1.4;
  line-height: var(--lh-h2);
  font-weight: 700;
  font-weight: var(--fw-bold);
}

.fz-xsmall {
  font-size: 1.3rem;
  font-size: var(--fz-xsmall);
}

.fz-small {
  font-size: 1.6rem;
  font-size: var(--fz-small);
}

.fz-large {
  font-size: 2.2rem;
  font-size: var(--fz-large);
}

.fw-bold {
  font-weight: 700;
  font-weight: var(--fw-bold);
}

a {
  font-family: Space Grotesk, -apple-system, BlinkMacSystemFont, Segoe UI, Arial, sans-serif;
  font-family: var(--font-base);
  color: #4e6169;
  color: var(--dark-grey);
  height: auto;
  white-space: break-spaces;
  width: auto;
  text-align: left;
  word-break: break-all;
  word-break: break-word;
  -webkit-user-select: none;
  user-select: none;
  justify-content: flex-start;
  font-weight: bold;
  line-height: 1.3;
  text-decoration: none;
  transition: background .15s, opacity .2s;
  display: inline-block;
}

a > span {
  margin-bottom: -.8rem;
  padding-bottom: .8rem;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

a > span:before, a > span:after {
  content: "";
  width: 100%;
  border-bottom: 2px solid #4e6169;
  border-bottom: 2px solid var(--dark-grey);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

a > span:before {
  transition: transform 1s cubic-bezier(.23, 1, .32, 1) .2s;
}

a > span:after {
  transition: transform 1s cubic-bezier(.23, 1, .32, 1);
  transform: translateX(-102%);
}

a:hover, a:focus {
  background: none;
}

:is(a:hover, a:focus) > span:before {
  transition: transform 1s cubic-bezier(.23, 1, .32, 1);
  transform: translateX(102%);
}

:is(a:hover, a:focus) > span:after {
  transition: transform 1s cubic-bezier(.23, 1, .32, 1) .2s;
  transform: translateX(0);
}

.l-icon {
  height: 100%;
  width: 100%;
  fill: #000;
  fill: currentColor;
  vertical-align: -.1em;
  display: inline-block;
}

.l-icon--xxs {
  width: .8rem;
  height: .8rem;
}

.l-icon--xs {
  width: 1.1rem;
  height: 1.1rem;
}

.l-icon--sm {
  width: 1.4rem;
  height: 1.4rem;
}

.l-icon--md {
  width: 2rem;
  height: 2rem;
}

.l-icon--mid {
  width: 3rem;
  height: 3rem;
}

.l-icon--lg {
  width: 4rem;
  height: 4rem;
}

.l-icon--xl {
  width: 6rem;
  height: 6rem;
}

.l-icon--rotate-90 {
  transform: rotate(90deg);
}

.l-icon--rotate-90--neg {
  transform: rotate(-90deg);
}

.l-icon--rotate-180 {
  transform: rotate(180deg);
}

.l-icon--flip {
  transform: scale(-1);
}

.l-icon--flip-y {
  transform: scaleY(-1);
}

.l-icon--flip-x {
  transform: scaleX(-1);
}

.l-icon--flip-and-rotate {
  transform: rotate(90deg)scaleX(-1);
}

.l-icon--rotate-animation {
  animation: 1.4s cubic-bezier(.79, .37, .17, .65) infinite animationRotate360;
}

.l-icon__wrap {
  align-items: center;
  display: inline-flex;
}

.l-icon__wrap > .l-icon {
  flex: 1 0 auto;
}

.l-icon__wrap > .l-icon:first-child {
  margin-right: 1.6rem;
}

.l-icon__wrap > .l-icon:last-child {
  margin-left: 1.6rem;
}

@media (max-width: 47.999em) {
  .l-icon__wrap > .l-icon:first-child {
    margin-right: 1rem;
  }

  .l-icon__wrap > .l-icon:last-child {
    margin-left: 1rem;
  }
}

.l-icon__wrap.btn:not(.btn--nobutton) > .l-icon:first-child {
  margin-left: -.5rem;
}

.l-icon__wrap.btn:not(.btn--nobutton) > .l-icon:last-child {
  margin-right: -.5rem;
}

.l-icon__wrap > span:not(.l-icon) {
  width: 100%;
}

.l-icon__wrap--align-start {
  align-items: flex-start;
}

.l-icon__wrap--align-start .l-icon {
  top: 6px;
}

.l-icon__wrap--sm > .l-icon:first-child {
  margin-right: 1rem;
}

.l-icon__wrap--sm > .l-icon:last-child {
  margin-left: 1rem;
}

@media (max-width: 47.999em) {
  .l-icon__wrap--sm > .l-icon:first-child {
    margin-right: .6rem;
  }

  .l-icon__wrap--sm > .l-icon:last-child {
    margin-left: .6rem;
  }
}

@media (max-width: 47.999em) {
  .l-icon__wrap--mobile-large > .l-icon:first-child {
    margin-right: 1.6rem;
  }

  .l-icon__wrap--mobile-large > .l-icon:last-child {
    margin-left: 1.6rem;
  }
}

@keyframes animationRotate360 {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.c-stylement__bg {
  background-color: #b6e7d4;
  background-color: var(--main-bg);
  width: calc(100vw - 50%);
  height: calc(100vh - 10rem);
  transform-origin: top;
  z-index: -1;
  position: absolute;
  top: 10rem;
  left: calc(100vw - 85%);
  transform: scaleY(0);
}

@media (max-width: 47.999em) {
  .c-stylement__bg {
    top: 6rem;
  }
}

.c-headerbar__container {
  z-index: 99999;
  position: sticky;
  top: 0;
}

.c-headerbar__wrapper {
  min-height: 10rem;
  padding: 0 5rem;
  padding: 0 var(--m-large);
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.c-toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.c-toggle__wrapper {
  width: 64px;
  height: 32px;
  background-color: #e7e7e7;
  background-color: var(--lighter-grey);
  border-radius: 16px;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  display: inline-flex;
  position: relative;
}

.c-toggle__wrapper:before, .c-toggle__wrapper:after {
  content: "";
  width: 32px;
  height: 32px;
  background-image: url("light.6231eb1e.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 15px 15px;
}

.c-toggle__wrapper:after {
  background-image: url("dark.dce09aac.svg");
}

.c-toggle__wrapper > input:checked + .c-toggle__slider:before {
  background-image: url("dark.dce09aac.svg");
  transform: translateX(32px)scale(1.1);
}

.c-toggle__slider {
  cursor: pointer;
  color: #4e6169;
  color: var(--dark-grey);
  position: absolute;
  inset: 0;
}

.c-toggle__slider:before {
  content: "";
  width: 32px;
  height: 32px;
  z-index: 1;
  background-color: #fff;
  background-image: url("light.6231eb1e.svg"), none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 15px 15px;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  border-radius: 32px;
  line-height: 1.2;
  transition: all .3s;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scale(1.1);
  box-shadow: -1px 0 4px #17161a0d;
}

.c-work__item {
  text-align: center;
  position: relative;
}

.c-work__item:hover .c-work__title {
  opacity: 1;
  transform: translateX(0)translateY(-20px);
}

.c-work__item:hover .c-work__image {
  transform: translateZ(0)scale(.9);
}

.c-work__item:hover .c-work__image img {
  transform: translateZ(0)translate(-50%, -50%)scale(1.5);
}

.c-work__item:hover .c-work__image canvas {
  transform: translateZ(0)scale(.9);
}

.c-work__item:nth-child(4n+2) canvas, .c-work__item:nth-child(4n+3) canvas {
  height: 368px;
}

.c-work__title {
  z-index: 0;
  opacity: 0;
  font-size: 2.6rem;
  transition: all .7s cubic-bezier(.17, .67, 0, 1.01);
  position: relative;
  transform: translateX(0)translateY(-50px);
}

.c-work__title-link {
  color: #054771;
  color: var(--dark-blue);
  font-weight: 400;
  text-decoration: none;
}

.c-work__image {
  z-index: 1;
  box-sizing: border-box;
  transition: all .7s cubic-bezier(.17, .67, 0, 1.01);
  display: flow-root;
  position: relative;
  overflow: hidden;
}

.c-work__image canvas {
  vertical-align: middle;
  max-width: 100%;
  box-sizing: border-box;
  width: 368px;
  height: 552px;
  transition: all .7s cubic-bezier(.17, .67, 0, 1.01);
}

@media (max-width: 47.999em) {
  .c-work__image canvas {
    width: 368px;
    height: 368px;
  }
}

.c-work__image img {
  opacity: 0;
  width: 100%;
  object-fit: cover;
  height: 100%;
  transform-origin: 0;
  filter: grayscale();
  transition: all .7s cubic-bezier(.17, .67, 0, 1.01);
  position: absolute;
}

.c-work__link {
  position: absolute;
  inset: 0;
}

.c-nav__list {
  justify-content: flex-end;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.c-nav__item {
  flex-flow: column wrap;
  align-items: center;
  padding: 0 2rem;
  display: flex;
}

.c-nav__item:last-child {
  padding-right: 0;
}

@media (min-width: 48em) {
  .c-nav__item {
    height: 100%;
    position: relative;
  }
}

@media (min-width: 64em) {
  .c-nav__item {
    padding: 0 2rem;
  }
}

.c-nav__link {
  color: #054771;
  color: var(--dark-blue);
  font-weight: 400;
}

/*# sourceMappingURL=index.fa78ed9e.css.map */
