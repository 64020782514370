@import "./base/normalize.css";
@import "./base/variables.css";
@import "./base/fonts.css";
@import "./base/base.css";

@import "./layout/grid.css";
@import "./layout/image.css";
@import "./layout/item.css";
@import "./layout/list.css";

@import "./contentblocks/hero.css";
@import "./contentblocks/work.css";
@import "./contentblocks/skills.css";
@import "./contentblocks/experience.css";
@import "./contentblocks/work-project.css";
@import "./contentblocks/footer.css";

@import "./elements/type.css";
@import "./elements/link.css";
@import "./elements/icon.css";
@import "./elements/stylement.css";

@import "./components/headerbar.css";
@import "./components/toggle.css";
@import "./components/work-item.css";
@import "./components/navigation.css";